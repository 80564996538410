<template>
  <div class="permissions_wrapper">
    <div class="text shadow">
      <v-icon color="#42BD4A" size="122px">{{ icon }}</v-icon>
      <h1>{{ title }}</h1>
      <p>
        {{ text }}
      </p>
    </div>
    <button
      @click="readyForPermission()"
      class="button green full_width shadow"
    >
      <v-icon left color="white">mdi-check</v-icon>Toegang geven
    </button>
    <permissionAllow
      v-if="this.isReady"
      v-bind:permission="this.permissions[this.count]"
    >
    </permissionAllow>
  </div>
</template>

<script>
import permissionAllow from "./permission_allow.vue";
export default {
  components: { permissionAllow },
  name: "permissions",
  props: ["permissions"],
  data: function () {
    return {
      permission_object: {},
      isReady: false,
      count: 0,
      title: "",
      text: "",
      icon: "",
    };
  },
  watch: {
    'permissions': {
      handler: function() {
        console.log("test incoming");
        let self = this;
        if (this.permissions[this.count].allowed) {
          self.count++;
          this.isReady = false
        }
      },
      deep: true,
    },
    count: function () {
      this.title = this.permissions[this.count].title;
      this.text = this.permissions[this.count].text;
      this.icon = this.permissions[this.count].icon;
    },
  },
  methods: {
    readyForPermission: function () {
      this.isReady = true;
    }
  },
  mounted() {
    this.title = this.permissions[this.count].title;
    this.text = this.permissions[this.count].text;
    this.icon = this.permissions[this.count].icon;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.permissions_wrapper {
  width: 100%;
  height: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  background-color: var(--lightest-color);
}

.text {
  padding: 48px;
  border-radius: 10px;
  box-sizing: border-box;
  flex-grow: 1;
  background-color: white;
}

.text p,
.text h1 {
  text-align: left;
}

.text h1 {
  font-size: 24px;
  font-weight: 800;
  color: black;
}

button {
  margin-top: 32px;
}
</style>
