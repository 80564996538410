<template>
	<div class="full_wrapper">
		<div class="camera_wrapper">
			<div v-show="!made_picture" class="recticle portrait"></div>
			<div v-show="!made_picture" class="recticle landscape"></div>
			<div
				@click="closeModal()"
				v-show="redo_picture"
				class="modal glassmorphism shadow"
			>
				<p>{{ this.popup_text }}</p>
				<button @click="closeModal()">
					<v-icon color="black">mdi-close</v-icon>
				</button>
			</div>
			<v-touch
				v-show="!made_picture"
				class="pinch_wrapper"
				v-on:pinchstart="zoomPinchStart($event)"
				v-on:pinchend="zoomPinchEnd($event)"
				v-on:pinchmove="zooming($event)"
			></v-touch>

			<video
				id="video"
				v-show="!made_picture"
				:width="this.set_width"
				:height="this.set_height"
				autoplay
			></video>

			<div v-show="!made_picture" class="zoomwrapper">
				<!-- <p>{{ current_zoom }}</p> -->
				<div class="container">
					<button
						class="zoomButton"
						v-bind:class="{ active: current_zoom == min_zoom }"
						@click="zoomCamera(min_zoom)"
					>
						<span>1x</span>
					</button>

					<button
						class="zoomButton"
						v-bind:class="{ active: current_zoom == zoom_buttons.quarter }"
						@click="zoomCamera(zoom_buttons.quarter)"
					>
						<span>{{ zoom_buttons.quarter }}x</span>
					</button>
					<button
						v-show="show_zoom_settings"
						class="zoomButton"
						v-bind:class="{ active: current_zoom == zoom_buttons.half }"
						@click="zoomCamera(zoom_buttons.half)"
					>
						<span>{{ zoom_buttons.half }}x</span>
					</button>
					<button
						v-show="show_zoom_settings"
						class="zoomButton"
						@click="zoomCamera(zoom_buttons.full)"
						v-bind:class="{ active: current_zoom == zoom_buttons.full }"
					>
						<span>{{ zoom_buttons.full }}x</span>
					</button>
				</div>
			</div>
			<div v-if="waiting_screen" class="send_screen shadow">
				<v-progress-circular
					:width="3"
					color="green"
					indeterminate
				></v-progress-circular>
				<h1>Bezig met vesturen..</h1>
				<p>Even geduld</p>
			</div>
			<img
				v-show="made_picture_android && !waiting_screen"
				id="img"
				class="picture shadow"
				v-bind:class="{ polaroid: polaroidStyle }"
			/>
			<canvas
				id="img_ios"
				class="picture shadow"
				v-bind:class="{ polaroid: polaroidStyle }"
				v-show="this.made_picture_ios && !waiting_screen"
				:width="this.set_width"
				:height="this.set_height"
			></canvas>

			<div
				class="navigation_wrapper glassmorphism"
				v-bind:class="{ glassmorphism: !made_picture }"
			>
				<button
					class="button white shadow"
					v-show="show_back_button"
					@click="backFunction(back_button_function)"
				>
					<v-icon color="#3A3A3A">mdi-arrow-left</v-icon>
				</button>
				<button class="button white invisible">
					<v-icon color="#3A3A3A">mdi-arrow-left</v-icon>
				</button>
				<button
					class="button white shadow"
					v-if="!made_picture"
					@click="openMenu()"
				>
					<v-icon color="#3A3A3A">mdi-menu</v-icon>
				</button>
				<!-- <v-select
          v-show="change_camera"
          :items="cameras"
          label="Selecteer de camera"
          item-text="label"
          item-value="deviceid"
          solo
          outlined
          v-model="selected_camera"
        ></v-select> -->
			</div>
			<transition name="slideup">
				<div
					class="button_wrapper"
					v-bind:class="{ glassmorphism: !made_picture }"
					v-if="!made_picture"
				>
					<button
						class="button white shadow score"
						v-if="!made_picture"
						@click="goToScoreboard()"
					>
						<v-icon color="#3A3A3A">mdi-podium</v-icon>
					</button>
					<button
						class="button shadow camera"
						v-if="!made_picture"
						@click="takePicture()"
					>
						<v-icon color="white">mdi-camera</v-icon
						><span
							class="iconify"
							data-icon="mdi:camera"
							data-inline="false"
						></span>
					</button>
					<button
						class="button white shadow score invisible"
						v-if="!made_picture"
					>
						<v-icon color="#3A3A3A">mdi-medal</v-icon>
					</button>
				</div>
			</transition>
			<transition name="slidetoleft">
				<div class="button_wrapper" v-if="made_picture">
					<QuestionBox
						v-if="this.active_question_box"
						:question_text="this.questionbox.question"
						:answer_prim_icon="this.questionbox.answer.prim.icon"
						:answer_prim_text="this.questionbox.answer.prim.text"
						:answer_sec_icon="this.questionbox.answer.sec.icon"
						:answer_sec_text="this.questionbox.answer.sec.text"
						v-on:sendAnswer="questionBoxAnswer($event)"
					>
					</QuestionBox>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import QuestionBox from "./question_box.vue";

export default {
	components: { QuestionBox },
	name: "camera",
	props: ["cameraId", "isIos"],
	data: function() {
		return {
			made_picture: false,
			made_picture_ios: false,
			made_picture_android: false,
			set_width: 1000,
			set_height: 500,
			camera_width: 4096,
			camera_height: 2160,
			camera_info: "",
			cameras: new Array(),
			selected_camera: "",
			image_capture: "test",
			change_camera: false,
			image_object: null,
			image_source: null,
			mediaStream: null,
			current_location: null,
			current_picture: null,
			current_private: null,
			questionbox: {},
			active_question_box: false,
			api_response: null,
			show_back_button: false,
			back_button_function: null,
			polaroidStyle: false,
			redo_picture: false,
			countdown: 4,
			current_zoom: 1,
			max_zoom: 2,
			min_zoom: 1,
			steps_zoom: 1,
			show_zoom_settings: false,
			disable_slider: false,
			zoom_estimate: 1,
			zoom_estimate_start: 0,
			zoom_estimate_count: 0,
			amount_of_tries: 0,
			popup_text: "",
			waiting_screen: false,
		};
	},
	computed: {
		canvasStyle: function() {
			return {
				width: this.set_width + "px",
				height: this.set_height + "px",
				position: "absolute",
				top: "0",
				left: "0",
			};
		},
		zoom_buttons: function() {
			return {
				quarter: this.max_zoom * 0.25,
				half: this.max_zoom * 0.5,
				full: this.max_zoom,
			};
		},
	},
	watch: {
		cameraId: function(id) {
			console.log(this.cameraId);
			console.log("changing camera");
			if (id.length > 0) {
				console.log(id);
				if (this.isMobile()) {
					// this.stopCamera();
					console.log(id);
					this.setupMobile();
				} else {
					this.setupDesktop();
				}
			}
		},
		current_location: function(val) {
			if (val != null) {
				this.checkForAPI();
			}
		},
		current_picture: function(val) {
			if (val != null) {
				this.checkForAPI();
			}
		},
		current_private: function(val) {
			if (val != null) {
				this.checkForAPI();
			}
		},
		current_zoom: function(newVal) {
			this.zoomCamera(newVal);
		},
		amount_of_tries: function(val) {
			if (val == 2) {
				this.popup_text =
					"Lukt het niet? Geen probleem. Maak de foto zo goed mogelijk en stuur hem toch op.";
			} else {
				this.popup_text = "Probeer de foto zo duidelijk mogelijk te maken.";
			}
		},
		active_question_box: function(val) {
			console.log("is showing questionbox " + val);
		},
	},
	methods: {
		closeModal: function() {
			this.redo_picture = false;
			this.countdown = 4;
		},
		closeModalAutomatic: function() {
			const self = this;
			if (this.countdown > 0) {
				setTimeout(() => {
					self.countdown -= 1;
					self.closeModalAutomatic();
				}, 1500);
			} else {
				this.closeModal();
			}
		},
		takePicture: function() {
			this.redo_picture = false;
			if (!this.isIos) {
				this.takePictureAndroid();
			} else {
				this.takePictureIOS();
			}
		},
		takePictureAndroid: function() {
			const img = document.querySelector("img");

			let imageCapture = this.image_capture;

			imageCapture
				.takePhoto()
				.then((blob) => {
					img.src = URL.createObjectURL(blob);
					this.image_source = URL.createObjectURL(blob);
					this.made_picture_android = true;
					this.made_picture = true;
					this.image_object = blob;
					this.show_back_button = true;
					this.back_button_function = "deletePicture";
					this.questionClear();
				})
				.catch((error) => console.error("takePhoto() error:", error));
		},
		takePictureIOS: function() {
			this.made_picture_ios = true;
			this.made_picture = true;
			this.show_back_button = true;
			this.back_button_function = "deletePicture";

			var canvas = document.getElementById("img_ios");
			var context = canvas.getContext("2d");
			var video = document.getElementById("video");
			context.drawImage(video, 0, 0, this.set_width, this.set_height);
			this.questionClear();
		},
		setupMobile: function() {
			if (this.isIos) {
				this.setupMobileIOS();
			} else {
				this.setupMobileAndroid();
			}
		},
		questionClear: function() {
			this.$emit("firstPhoto");
			this.polaroidStyle = false;
			this.active_question_box = false;

			if (this.amount_of_tries > 1) {
				this.questionbox = {
					id: "logo",
					answer: {
						prim: {
							text: "Ja",
							icon: "mdi-check",
						},
						sec: {
							text: "Nee",
							icon: "mdi-close",
						},
					},
					question: "Wil je de foto zo sturen?",
				};
			} else {
				this.questionbox = {
					id: "logo",
					answer: {
						prim: {
							text: "Ja",
							icon: "mdi-check",
						},
						sec: {
							text: "Nee",
							icon: "mdi-close",
						},
					},
					question: "Is de foto scherp en staat de container in beeld?",
				};
			}
			this.active_question_box = true;
		},
		questionPrivate: function() {
			this.active_question_box = false;
			console.log("question box status" + this.active_question_box);

			this.polaroidStyle = true;
			this.back_button_function = "firstQuestion";
			this.questionbox = {
				id: "particulier",
				answer: {
					prim: {
						text: "Ja",
						icon: "mdi-check",
					},
					sec: {
						text: "Nee",
						icon: "mdi-close",
					},
				},
				question: "Is de container verhuurd aan een bedrijf?",
			};
			this.active_question_box = true;
		},
		questionBoxAnswer: function(val) {
			console.log(this.questionbox);
			this.active_question_box = false;
			if (this.questionbox.id == "logo") {
				if (val == "prim") {
					this.questionPrivate();
				} else {
					this.amount_of_tries++;
					this.show_back_button = false;
					this.back_button_function = "";
					this.redo_picture = true;
					this.closeModalAutomatic();
					this.deletePicture();
				}
			} else if (this.questionbox.id == "particulier") {
				if (val == "prim") {
					this.current_private = true;
					this.savePicture();
				} else {
					this.current_private = false;
					this.savePicture();
				}
			}
		},
		setupMobileAndroid: function() {
			var self = this;

			var video = document.getElementById("video");
			this.set_height = document.documentElement.clientHeight;
			this.set_width = document.documentElement.clientWidth;

			console.log("width " + this.set_width + " heigth " + this.set_height);

			var ratio = this.calculateRatio(this.set_width, this.set_height);
			console.log(ratio);

			this.camera_height = this.camera_height * ratio;
			this.camera_width = this.camera_width * ratio;

			let videosettings = {
				video: {
					// facingMode: 'environment',
					deviceId: this.cameraId,
					height: {
						ideal: this.camera_height,
					},
					width: {
						ideal: this.camera_width,
					},
				},
			};

			if (this.cameraId.length == 0) {
				videosettings = {
					video: {
						facingMode: "environment",
						height: {
							ideal: this.camera_height,
						},
						width: {
							ideal: this.camera_width,
						},
					},
				};
			}

			let mediaStreamTrack = null;
			let imageCapture = null;

			navigator.mediaDevices
				.getUserMedia(videosettings)
				.then(gotMedia)
				.catch((error) => console.error("getUserMedia() error:", error));

			function gotMedia(mediaStream) {
				mediaStreamTrack = mediaStream.getVideoTracks()[0];
				imageCapture = new ImageCapture(mediaStreamTrack);
				video.srcObject = mediaStream;
				self.image_capture = imageCapture;
				self.mediaStream = mediaStream;
				if ("zoom" in mediaStreamTrack.getSettings()) {
					self.max_zoom = mediaStreamTrack.getCapabilities().zoom.max;
					self.min_zoom = mediaStreamTrack.getCapabilities().zoom.min;
					self.steps_zoom = mediaStreamTrack.getCapabilities().zoom.step;
				}
				self.current_zoom = self.min_zoom;
			}
		},
		setupMobileIOS: function() {
			var self = this;
			var video = document.getElementById("video");
			this.set_height = document.documentElement.clientHeight;
			this.set_width = document.documentElement.clientWidth;

			console.log("width " + this.set_width + " heigth " + this.set_height);

			var ratio = 1;

			this.camera_height = this.camera_height * ratio;
			this.camera_width = this.camera_width * ratio;

			let videosettings = {
				video: {
					// facingMode: 'environment',
					deviceId: this.cameraId,
					height: {
						ideal: this.camera_height,
					},
					width: {
						ideal: this.camera_width,
					},
				},
			};

			if (this.cameraId.length == 0) {
				videosettings = {
					video: {
						facingMode: "environment",
						height: {
							ideal: this.camera_height,
						},
						width: {
							ideal: this.camera_width,
						},
					},
				};
			}

			// let mediaStreamTrack = null;
			// let imageCapture = null;

			navigator.mediaDevices
				.getUserMedia(videosettings)
				.then(gotMedia)
				.catch((error) => console.error("getUserMedia() error:", error));

			function gotMedia(mediaStream) {
				video.srcObject = mediaStream;
				video.setAttribute("playsinline", true);
				self.mediaStream = mediaStream;
			}
		},
		setupDesktop: function() {
			var video = document.getElementById("video");
			this.set_height = document.documentElement.clientWidth;
			this.set_width = document.documentElement.clientHeight;

			var ratio = this.calculateRatio(this.set_width, this.set_height);
			console.log(ratio);

			this.camera_height = this.camera_height * ratio;
			this.camera_width = this.camera_width * ratio;

			let videosettings = {
				video: {
					// facingMode: 'environment',
					deviceId: this.selected_camera,
					height: {
						ideal: this.camera_height,
					},
					width: {
						ideal: this.camera_width,
					},
				},
			};

			console.log(videosettings);

			if (navigator.mediaDevices.getUserMedia) {
				navigator.mediaDevices
					.getUserMedia(videosettings)
					.then(function(stream) {
						video.srcObject = stream;
					})
					.catch(function(error) {
						console.log(error);
					});
			}
		},
		deletePicture: function() {
			this.countdown = 4;
			const img = document.querySelector("img");
			img.onload = () => {
				URL.revokeObjectURL(this.src);
			};
			this.made_picture = false;
			this.made_picture_android = false;
			this.made_picture_ios = false;
		},
		savePicture: function() {
			var self = this;
			console.log("saving picture");
			if (this.isIos) {
				let canvas = document.getElementById("img_ios");
				canvas.toBlob(function(blob) {
					console.log(blob);
					var image = new File([blob], "container.jpg", { type: "images/jpg" });
					self.current_picture = image;
					self.getLocation();
					self.checkForAPI();
				}, "image/jpg");
			} else {
				let blob = new File([this.image_object], "container.jpg", {
					type: "images/jpg",
				});
				self.current_picture = blob;
				self.getLocation();
				self.checkForAPI();
			}
		},
		uploadPicture: function(blob) {
			const self = this;
			var unsignedUploadPreset = "sppmccfe";
			var url = `https://api.cloudinary.com/v1_1/dqcqit1nf/upload`;
			console.log(url);

			var fd = new FormData();
			fd.append("upload_preset", unsignedUploadPreset);
			fd.append("tags", "browser_upload"); // Optional - add tag for image admin in Cloudinary
			fd.append("folder", "testfase");
			fd.append("file", blob);

			this.waiting_screen = true;

			this.axios({
				url: url,
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
				data: fd,
			})
				.then(function(res) {
					self.getLocation();
					console.log(res);
					self.current_picture = res;
					self.show_back_button = false;
				})
				.catch(function(err) {
					console.log(err);
				});
		},
		location: function(position) {
			this.current_location = position;
		},
		getLocation: async function() {
			var location;
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(this.location);
			} else {
				console.log("no location");
			}
			return location;
		},
		pushToApi: function(img_file, location, is_private) {
			var self = this;
			this.waiting_screen = true;
			this.show_back_button = false;

			console.log(img_file, location, is_private);
			var url =
				"https://cre8ion-websites-concurentiemonitor-api-as.azurewebsites.net/Image/save";

			var fd = new FormData();

			fd.append("imageFile", img_file);
			fd.append("gpsData", location.lat + ", " + location.long);
			fd.append("isPrivate", is_private);

			this.axios({
				url: url,
				mode: "cors",
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				data: fd,
			})
				.then(function(res) {
					console.log("getting result api");
					console.log(res);
					self.api_response = res;
					self.doneWithApi(true);
				})
				.catch(function(err) {
					console.log(err.response.data);
					console.log(err.response.status);
					console.log(err.response.headers);

					self.doneWithApi(false);
				});
		},
		doneWithApi: function(result) {
			console.log(result);
			this.amount_of_tries = 0;
			this.current_location = null;
			this.current_picture = null;
			this.current_private = null;
			this.waiting_screen = false;
			this.current_zoom = this.min_zoom;
			this.uploadSucces();
		},
		isMobile: function() {
			if (
				/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
					navigator.userAgent
				)
			) {
				return true;
			} else {
				return false;
			}
		},
		cameraList: function() {
			let aCameras = new Array();
			navigator.mediaDevices.enumerateDevices().then(function(devices) {
				for (let i = 0; devices[i]; i++) {
					if (devices[i].kind == "videoinput") {
						aCameras.push({
							deviceid: devices[i].deviceId,
							label: devices[i].label,
							everything: devices[i],
						});
					}
				}
			});

			this.cameras = aCameras;
		},
		calculateRatio: function(num_1, num_2) {
			for (let num = num_2; num > 1; num--) {
				if (num_1 % num == 0 && num_2 % num == 0) {
					num_1 = num_1 / num;
					num_2 = num_2 / num;
				}
			}
			// var ratio = num_1+":"+num_2;
			var ratio = num_1 / num_2;
			return ratio;
		},
		changeCamera: function() {
			this.change_camera = !this.change_camera;
		},
		stopCamera: function() {
			console.log(this.mediaStream);
			this.mediaStream.getTracks().forEach(function(track) {
				track.stop();
			});
		},
		uploadSucces: function() {
			var self = this;
			console.log("uploaded picture");
			this.deletePicture();
			console.log(self.api_response);
			this.$emit("sendPicture", [true, self.api_response]);
		},
		checkForAPI: function() {
			if (
				this.current_private != null &&
				this.current_picture != null &&
				this.current_location != null
			) {
				var camera = this.current_picture;
				console.log(this.current_picture);
				console.log(this.current_location);
				var location = {
					lat: this.current_location.coords.latitude,
					long: this.current_location.coords.longitude,
				};

				this.pushToApi(camera, location, this.current_private);
			}
		},
		backFunction: function(redirect) {
			var self = this;
			switch (redirect) {
				case "deletePicture":
					this.active_question_box = false;
					this.show_back_button = false;
					this.back_button_function = "";
					self.deletePicture();
					break;
				case "firstQuestion":
					this.questionClear();
					this.back_button_function = "deletePicture";
					break;

				default:
					break;
			}
		},
		goToScoreboard: function() {
			this.$emit("goToScoreboard");
		},
		openMenu: function() {
			this.$emit("openMenu");
		},
		zoomCamera: function(zoom_val_in) {
			this.show_zoom_settings = true;
			console.log(zoom_val_in);
			const self = this;

			var mediaStream = self.mediaStream;
			var mediaStreamTrack = mediaStream.getVideoTracks()[0];

			if (!("zoom" in mediaStreamTrack.getSettings())) {
				return Promise.reject(
					"Zoom is not supported by " + mediaStreamTrack.label
				);
			}

			self.max_zoom = mediaStreamTrack.getCapabilities().zoom.max;
			self.min_zoom = mediaStreamTrack.getCapabilities().zoom.min;
			self.steps_zoom = mediaStreamTrack.getCapabilities().zoom.step;

			var new_zoom = zoom_val_in;
			if (new_zoom <= self.max_zoom) {
				self.current_zoom = new_zoom;
			} else {
				new_zoom = self.max_zoom;
				self.current_zoom = new_zoom;
			}

			if (new_zoom >= self.min_zoom) {
				self.current_zoom = new_zoom;
			} else {
				new_zoom = self.min_zoom;
				self.current_zoom = new_zoom;
			}

			if (!(this.current_zoom > this.min_zoom)) {
				setTimeout(function() {
					self.show_zoom_settings = false;
				}, 2000);
			}

			mediaStreamTrack.applyConstraints({
				advanced: [{ zoom: this.current_zoom }],
			});
		},
		cameraZoomPinch: function(distance, direction) {
			var multiplier = 0.1;
			if (direction == "pinchout") {
				console.log("zooming in");
				this.current_zoom += distance * multiplier;
			} else {
				console.log("zooming out");

				this.current_zoom -= distance * multiplier;
			}
			console.log(this.current_zoom);
		},
		zoomPinchStart: function(e) {
			this.show_zoom_settings = true;

			console.log(e);
			console.log("pinch start");
			this.zoom_estimate_start = this.current_zoom;
		},
		zoomPinchEnd: function(e) {
			console.log(e);
			this.cameraZoomPinch(e.distance, e.additionalEvent);
			console.log("pinch end");
			this.zoom_estimate_start = this.current_zoom;
		},
		zooming: function() {
			//visual zoom
		},
	},
	mounted() {
		if (this.isMobile()) {
			console.log("Mobile");
			console.log("IOS check " + this.isIos);
			if (this.isIos != false) {
				this.setupMobileIOS();
			} else {
				this.setupMobile();
			}
			this.cameraList();
		} else {
			console.log("Desktop");
			this.cameraList();
			// this.setupDesktop();
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button_wrapper {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	display: flex;
	padding: 24px;
	justify-content: space-between;
	z-index: 15;
}

.setup_wrapper {
	padding: 24px;
}

.camera_wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--lightest-color);
}

.navigation_wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 24px;
	z-index: 15;
}

.button.camera {
	background-color: #d01111;
	width: 132px;
	border-radius: 50px;
	padding: 24px;
}

.button.camera:active {
	background-color: #720000;
}

.button.score {
	align-self: center;
	padding: 16px;
}

.invisible {
	opacity: 0;
}

#img,
#img_ios {
	object-fit: cover;
	/* width: 100%; */
	/* height: 100%; */
	/* position: absolute; */
	bottom: 0;
	left: 0;
}

.picture {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	margin: 0px auto;
	position: relative;
	transition: 0.5s;
}

.polaroid {
	max-height: calc(100% - 340px);
	width: 83%;
	transform: rotate(2deg);
	border: 8px solid white;
	top: 110px;
	object-fit: cover !important;
}

.recticle {
	border: 2px solid white;
	height: 100%;
	width: 100%;
	position: absolute;
	box-shadow: 0px 2px 12px 0px rgb(56 70 57 / 10%);
}

.recticle.landscape {
	height: calc(100% - 350px);
	top: calc(350px / 2);
	border-right: none;
	border-left: none;
}

.recticle.portrait {
	width: calc(100% - 60px);
	left: calc(60px / 2);
}

.modal {
	top: calc(107px);
	position: absolute;
	margin: 24px;
	border-radius: 10px;
	background-color: rgba(255, 255, 255, 0.6);
	padding: 16px 24px;
	text-align: left;
	z-index: 10;
	display: flex;
	align-items: flex-start;
	transition: 0.5s;
}

.modal button {
	padding: 4px;
}

.modal p {
	margin: 0;
	font-size: 18px;
	color: #000000;
	text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.2);
}

.zoomwrapper {
	position: absolute;
	bottom: calc(123px + 16px);
	width: 100%;
	box-sizing: border-box;
	padding: 0 24px;
	z-index: 10;
	transition: 0.6s;
}

.pinch_wrapper {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 10 !important;
}

.zoomButton {
	color: white;
	background: rgba(0, 0, 0, 0.8);
	border: 2px solid rgba(255, 255, 255, 0.3);
	font-weight: 500;
	border-radius: 50%;
	min-width: 54px;
	max-width: 120px;
	text-decoration: none;
	display: inline-block;
	margin: 0 8px;
	transition: 0.6s;
}

.zoomButton.active {
	border: 2px solid rgba(255, 255, 255, 1);
}

.zoomwrapper .container {
	display: flex;
	justify-content: center;
}

.zoomButton:before {
	content: "";
	display: inline-block;
	vertical-align: middle;
	padding-top: 100%;
}
.zoomButton span {
	display: inline-block;
	vertical-align: middle;
	max-width: 90%;
}

.send_screen {
	padding: 24px;
	background: white;
	top: calc(50% - calc(168px));
	margin: 24px;
	position: relative;
	border-radius: 10px;
}

.send_screen * {
	margin-bottom: 16px;
}

:root {
	--short-timing: 0.2s;
	--normal-timing: 0.4s;
}
</style>
