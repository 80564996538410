<template>
  <v-app class="ma-0">
    <v-main>
      <tip
        v-if="showtip"
        :title="currenttip.title"
        :text="currenttip.text"
        v-on:closeTip="closeTip()"
      >
      </tip>
      <onboardingHomescreen
        v-if="showHomeScreenOnboarding()"
        :isIos="checkIOS()"
        v-on:backToCamera="closeHomeScreen()"
      >
      </onboardingHomescreen>
      <transition name="expand">
        <Menu
          v-if="this.show_menu"
          v-on:closeMenu="closeMenu()"
          v-on:changeCamera="changeCamera($event)"
          v-on:resetScore="resetScore()"
        ></Menu>
      </transition>

      <login
        v-if="!this.loggedin"
        v-bind:user="this.user"
        v-on:pageRedirect="pageRedirectLogin($event)"
      />
      <span v-if="goToAdminPage()">
        <AdminOverview> </AdminOverview>
      </span>
      <span v-else>
        <permissions
          v-if="!permissionsDone() && this.loggedin"
          v-bind:permissions="this.permissions"
        />

        <camera
          v-if="showCamera() && this.loggedin"
          :cameraId="this.cameraId"
          :isIos="checkIOS()"
          :firstTimeSetup="this.firstTimeSetup"
          v-on:sendPicture="doneCameraRedirect($event)"
          v-on:goToScoreboard="goToScoreboard()"
          v-on:openMenu="openMenu()"
          v-on:firstPhoto="showTip('photo_clear')"
        >
        </camera>
        <DoneScreen
          v-if="showEndScreen()"
          :score="score"
          :prediction="prediction_object"
          v-on:doneScreenAnswer="doneScreenRedirect($event)"
        ></DoneScreen>
        <transition name="slideup">
          <Scoreboard
            v-if="showScoreboard()"
            :users="this.users"
            :curUser="this.user"
            v-on:backToCamera="doneScreenRedirect('camera')"
            v-on:openMenu="openMenu()"
          ></Scoreboard>
        </transition>
      </span>
    </v-main>
  </v-app>
</template>

<script>
import login from "./components/login.vue";
import camera from "./components/camera.vue";
import permissions from "./components/permissions.vue";
import DoneScreen from "./components/DoneScreen.vue";
import AdminOverview from "./components/AdminOverview.vue";
import Scoreboard from "./components/Scoreboard.vue";
import Menu from "./components/Menu.vue";
import tip from "./components/tip.vue";
import onboardingHomescreen from "./components/onboarding_homescreen.vue";

export default {
  name: "App",

  components: {
    camera,
    login,
    permissions,
    DoneScreen,
    AdminOverview,
    Scoreboard,
    Menu,
    tip,
    onboardingHomescreen,
  },

  data: () => ({
    done_screen: false,
    score: 2,
    prediction_object: null,
    show_scoreboard: false,
    show_menu: false,
    cameraId: "",
    firstTimeSetup: false,
    firsttime_camera: false,
    firsttime_photo: false,
    firsttime_score: false,
    firsttime_homescreen: false,
    permissions_done: false,
    showtip: false,
    currenttip: {},
    tip: {
      camera: {
        type: "camera",
        title: "Foto's maken",
        text:
          "Maak zoveel mogelijk foto’s van afzetcontainers en stuur deze per container op. Probeer deze zo duidelijk mogelijk op de foto te krijgen!",
      },
      photo_clear: {
        type: "photo_clear",
        title: "Foto controleren",
        text:
          "Wanneer de foto is gemaakt heb je nog even tijd om deze te controleren. Staat er een logo op de afzetcontainer? Probeer deze dan zo duidelijk mogelijk in de foto te krijgen.",
      },
      score: {
        type: "score",
        title: "Punten Scoren",
        text:
          "Op het scorebord zie je je totale punten, scoor de meeste punten en maak kans op leuke prijzen!",
      },
    },
    user: {
      id: 1,
      name: "Sven",
      password: "ja",
      loggedin: true,
      is_admin: false,
      score: 0,
    },
    users: [
      {
        name: "John",
        last_name: "Doe",
        id: 1,
        score: 0,
      },
      {
        id: 2,
        name: "Henk",
        last_name: "Janssen",
        score: 1,
      },
      {
        id: 3,
        name: "Jan",
        last_name: "Pieter",
        score: 2,
      },
      {
        id: 4,
        name: "Tim",
        last_name: "Janssen",
        score: 5,
      },
      {
        id: 5,
        name: "Piet",
        last_name: "Timmerman",
        score: 0,
      },
      {
        id: 6,
        name: "Kim",
        last_name: "Eriksen",
        score: 0,
      },
      {
        id: 7,
        name: "Rik",
        last_name: "Peeters",
        score: 0,
      },
    ],
    permissions: [
      {
        name: "geolocation",
        text:
          "De locatie zal enkel worden gebruikt om de locatie van de foto op te slaan.",
        title: "Toegang geven tot je locatie",
        icon: "mdi-map-marker",
        asked: false,
        allowed: false,
      },
      {
        name: "camera",
        text:
          "De website zal gebruik maken van je camera om de foto's te maken.",
        title: "Toegang tot je camera",
        icon: "mdi-camera",
        asked: false,
        allowed: false,
      },
    ],
  }),
  methods: {
    checkIOS: function () {
      // return true;
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
    checkLogin: function (username, password) {
      if (username == this.user.name && password == this.user.password) {
        this.user.loggedin = true;

        return true;
      } else {
        return false;
      }
    },
    doneScreenRedirect: function (action) {
      if (action == "camera") {
        this.done_screen = false;
        this.show_scoreboard = false;
        this.showHomeScreenOnboarding();
      } else if (action == "score") {
        this.done_screen = false;
        this.show_scoreboard = true;
      }
    },
    doneCameraRedirect: function (val) {
      console.log(val);
      var api_score = val[1];
      this.addScoreCurrentUser(1);
      // api_score = JSON.parse(api_score);
      // api_score = api_score.predictions[0]
      this.prediction_object = api_score;
      this.done_screen = true;
    },
    addScoreCurrentUser: function (score) {
      var self = this;
      this.user.score += score;
      this.users.forEach(function (user) {
        if (user.id == self.user.id) {
          user.score += score;
          localStorage.score = user.score;
        }
      });
    },
    resetScore: function () {
      var self = this;
      this.user.score = 0;
      localStorage.firsttime_camera = true;
      localStorage.firsttime_score = true;
      localStorage.firsttime_photo = true;
      localStorage.permissions_done = false;
      localStorage.firsttime_homescreen = true;

      this.users.forEach(function (user) {
        if (user.id == self.user.id) {
          user.score = 0;
          localStorage.score = user.score;
        }
      });
    },
    showCamera: function () {
      if (
        this.permissionsDone() &&
        !this.done_screen &&
        !this.show_scoreboard
      ) {
        this.showTip("camera");
        return true;
      } else {
        return false;
      }
    },
    showEndScreen: function () {
      if (this.permissionsDone() && this.done_screen && !this.show_scoreboard) {
        return true;
      } else {
        return false;
      }
    },
    showScoreboard: function () {
      if (this.permissionsDone() && this.show_scoreboard) {
        this.showTip("score");
        return true;
      } else {
        return false;
      }
    },
    openMenu: function () {
      console.log("open menu");
      this.show_menu = true;
    },
    closeMenu: function () {
      console.log("close menu");
      this.show_menu = false;
    },
    permissionsDone: function () {
      let done = 0;
      if (this.permissions_done && !this.checkIOS()) {
        return true;
      }

      this.permissions.forEach(function (val) {
        if (val.asked && val.allowed) {
          done++;
        }
      });

      if (done == this.permissions.length) {
        localStorage.permissions_done = true;
        this.permissions_done = true;
        return true;
      } else {
        return false;
      }
    },
    pageRedirectLogin: function (val) {
      if (val == "admin") {
        this.user.is_admin = true;
      } else {
        this.user.is_admin = false;
      }
      this.user.loggedin = true;
    },
    goToAdminPage: function () {
      if (this.loggedin && this.user.is_admin) {
        return true;
      } else {
        return false;
      }
    },
    goToScoreboard: function () {
      console.log("showscoreboard");
      this.show_scoreboard = true;
    },
    changeCamera: function (val) {
      console.log(val);
      localStorage.cameraId = val;
      this.cameraId = val;
    },
    closeTip: function () {
      if (this.currenttip.type == "camera") {
        this.firsttime_camera = false;
        localStorage.firsttime_camera = false;
      }

      if (this.currenttip.type == "photo_clear") {
        this.firsttime_photo = false;
        localStorage.firsttime_photo = false;
      }

      if (this.currenttip.type == "score") {
        this.firsttime_score = false;
        localStorage.firsttime_score = false;
      }

      this.showtip = false;
    },
    showTip: function (type) {
      if (type == "camera") {
        if (this.firsttime_camera && this.firsttime_camera != "false") {
          this.currenttip = this.tip.camera;
          this.showtip = true;
        }
      }

      if (type == "photo_clear") {
        if (this.firsttime_photo && this.firsttime_photo != "false") {
          this.currenttip = this.tip.photo_clear;
          this.showtip = true;
        }
      }

      if (type == "score") {
        if (this.firsttime_score && this.firsttime_score != "false") {
          this.currenttip = this.tip.score;
          this.showtip = true;
        }
      }
    },
    closeHomeScreen: function () {
      this.firsttime_homescreen = false;
      localStorage.firsttime_homescreen = false;
    },
    showHomeScreenOnboarding: function () {
      console.log("check onboarding");
      console.log(this.users[0]);
      console.log(this.firsttime_homescreen);
      if (
        (this.users[0].score >= 2) &&
        (this.firsttime_homescreen == true) &&
        (this.done_screen == false) &&
        (this.showScoreboard() == false) &&
        (this.permissionsDone() == true)
      ) {
        console.log("check onboarding pos");

        return true;
      } else {
        console.log("check onboarding neg");

        return false;
      }
    },
  },
  watch: {
    permissions: {
      handler: function () {
        console.log("checking permissions in main");
        this.permissionsDone();
      },
      deep: true,
    },
  },
  computed: {
    loggedin: function () {
      return this.user.loggedin;
    },
  },
  mounted() {
    console.log("what?");
    const self = this;
    if (localStorage.score) {
      console.log("localstorage" + localStorage.score);
      self.users[0].score = parseInt(localStorage.score);
    } else {
      console.log(self.users[0].score);
    }

    if (localStorage.firstTime) {
      this.firstTimeSetup = localStorage.firstTimeSetup;
    } else {
      localStorage.firstTimeSetup = true;
    }

    if (localStorage.firsttime_camera) {
      this.firsttime_camera = localStorage.firsttime_camera;
    } else {
      localStorage.firsttime_camera = true;
    }

    if (localStorage.firsttime_photo) {
      this.firsttime_photo = localStorage.firsttime_photo;
    } else {
      localStorage.firsttime_photo = true;
    }

    if (localStorage.firsttime_score) {
      this.firsttime_score = localStorage.firsttime_score;
    } else {
      localStorage.firsttime_score = true;
    }

    if (localStorage.firstTime) {
      this.firstTimeSetup = localStorage.firstTimeSetup;
    } else {
      localStorage.firstTimeSetup = true;
    }

    if (localStorage.permissions_done) {
      console.log(localStorage.permissions_done);
      if (
        localStorage.permissions_done ||
        localStorage.permissions_done == "true"
      ) {
        this.permissions_done = true;
      }

      if (
        !localStorage.permissions_done ||
        localStorage.permissions_done == "false"
      ) {
        this.permissions_done = false;
      }
    }

    if (localStorage.firsttime_homescreen) {
      if (
        localStorage.firsttime_homescreen ||
        localStorage.firsttime_homescreen == "true"
      ) {
        this.firsttime_homescreen = true;
      }

      if (
        !localStorage.firsttime_homescreen ||
        localStorage.firsttime_homescreen == "false"
      ) {
        this.firsttime_homescreen = false;
      }
    }

    if (localStorage.cameraId) {
      this.cameraId = localStorage.cameraId;
    }
  },
};

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

// We listen to the resize event
window.addEventListener("resize", () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});
</script>

<style>
:root {
  --white-color: #ffffff;
  --primairy-color: #318d37;
  --light-color: #42bd4a;
  --lighter-color: #a8e1ac;
  --lighter-2-color: #d9f2db;
  --lightest-color: #f7fcf8;
  --darker-color: #246628;
  --darkest-color: #113113;
  --black-color: #000000;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.shadow {
  box-shadow: 0px 2px 12px 0px rgb(56 70 57 / 10%),
    0px 2px 7px 0px rgb(59 71 60 / 11%), 0px 1px 1px 0px rgb(53 59 54 / 24%);
}

.button {
  border: none;
  border-radius: 10px;
  padding: 16px 24px;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
}

.button.white {
  background: var(--white-color);
  color: var(--darker-color);
}

.button.white:active {
  background: rgb(211, 211, 211) !important;
}

.button.green {
  background: var(--darkest-color) !important;
  color: var(--lightest-color) !important;
}

.button.green:active {
  background: var(--darker-color) !important;
}

.button.full_width {
  width: 100%;
}

.button.spacing {
  margin: 0 8px;
}

button:hover,
button:active,
button {
  outline: none;
}

.v-application {
  margin: 0px !important;
}

.v-application--wrap {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100) !important;
}

.glassmorphism {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(104, 104, 104, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.zoomslider .v-slider__track-container {
  background-color: rgba(0, 0, 0, 0) !important;
  background: repeating-linear-gradient(
    to right,
    #ffffff,
    #ffffff 3px,
    #ffd18000 3px,
    #ffd18000 15px
  ) !important;
  height: 15px;
  /* opacity: 0; */
  transition: 0.5s;
}

.v-slider__track-background,
.v-slider__track-fill {
  opacity: 0 !important;
}

.v-slider--horizontal {
  margin-left: calc(72px / 2);
  margin-right: calc(72px / 2);
}

.zoomslider .v-slider__thumb-label {
  transform: unset !important;
  font-size: 24px !important;
  font-weight: 600;
  border: 2px solid white !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 50%;
  transform: translate(calc(-72px / 2), calc(72px / 2)) !important;
}

.zoomslider .v-slider__thumb-label > div {
  transform: unset !important;
}

.slidetoleft-enter {
  transform: translateX(100%);
}

.slidetoleft-enter-to {
  transform: translateX(0%);
}

.slidetoleft-enter-active {
  transition: all 0.2s ease-in-out;
}

.slidetoleft-leave {
  transform: translateX(0%);
}

.slidetoleft-leave-to {
  transform: translateX(-100%);
}

.slidetoleft-leave-active {
  transition: all 0.2s ease-in-out;
}
.slideup-enter {
  transform: translateY(100%);
}

.slideup-enter-to {
  transform: translateY(0%);
}

.slideup-enter-active {
  transition: all 0.2s ease-in-out;
}

.slideup-leave {
  transform: translateY(0%);
}

.slideup-leave-to {
  transform: translateY(100%);
}

.slideup-leave-active {
  transition: all 0.2s ease-in-out;
}

.expand-enter {
  transform: scale(0%);
}

.expand-enter-to {
  transform: scale(100%);
}

.expand-enter-active {
  transition: all 0.2s ease-in-out;
}

.expand-leave {
  transform: scale(100%);
}

.expand-leave-to {
  transform: scale(0%);
}

.expand-leave-active {
  transition: all 0.2s ease-in-out;
}
</style>
