<template>
	<div class="admin_overview">

    <v-text-field
          v-model="search"
          label="Zoeken naar containers"
          class="mx-4"
        ></v-text-field>
    <v-data-table
    :headers="this.headers"
    :items="this.containers"
    :items-per-page="10"
    :search="search"
    color="white"
    class="elevation-1"
  ></v-data-table>
  </div>
</template>

<script>
export default {
	name: "AdminOverview",
	props: [],
	data: function() {
		return {
      search: '',
       headers: [
          {
            text: 'Bedrijf',
            align: 'start',
            value: 'company.name',
          },
          { text: 'Prijs', value: 'private_Price' },
          { text: 'Grootte', value: 'size' }
        ],
			containers: [],
		};
	},
	methods: {
		getContainers: function() {
    var self = this;

			var url =
				"https://cre8ion-websites-concurentiemonitor-api-as.azurewebsites.net/Container";

			this.axios({
				url: url,
				mode: "cors",
				method: "GET",
				headers: {
					Accept: "application/json",
				},
			})
				.then(function(res) {
          self.containers = res.data;
          console.log(self.containers)
					console.log(res.data);
				})
				.catch(function(err) {
					console.log(err);
				});
		},
	},
	computed: {},
  mounted(){
    this.getContainers();
  }
};
</script>
<style scoped>
.admin_overview {
  /* max-width: 1200px; */
  padding: 64px;
  margin: 0 auto;
  min-height: 100%;
  width: 100%;
 background-color: var(--lightest-color);
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
