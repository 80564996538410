<template>
  <div class="permissions_wrapper">
    <video v-if="videoCheck" id="videopermission" autoplay></video>
  </div>
</template>

<script>
export default {
  name: "permissionsAllow",
  props: ["permission"],
  data: function () {
    return {
      videoCheck: false,
    };
  },
  methods: {
    permissionResult: function (result) {
      console.log("change allowed to " + result);
      this.permission.asked = true;
      this.permission.allowed = result;
    },
    location: function (position) {
      console.log(position);
      this.permissionResult(true);
    },
    permissionLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.location);
      } else {
        this.permissionResult(false);
      }
    },
    permissionCamera() {
      var self = this;
      this.videoCheck = true;

      let videosettings = {
        video: {
          facingMode: "environment",
        },
      };

      navigator.mediaDevices
        .getUserMedia(videosettings)
        .then(gotMedia)
        .catch((error) => console.error("getUserMedia() error:", error));

      function gotMedia(mediaStream) {
        mediaStream.getTracks().forEach(function (track) {
          track.stop();
        });
        self.permissionResult(true);
      }
    },
  },
  computed: {},
  mounted() {
    // const self = this;
    console.log(this.permission);
    if (this.permission.name == "geolocation") {
      if (!this.permission.asked) {
        this.permissionLocation();
      } else {
        if (this.permission.asked && this.permission.allowed) {
          this.permissionResult(true);
        }
      }
    } else if (this.permission.name == "camera") {
      if (!this.permission.asked) {
        this.permissionCamera();
      } else {
        if (this.permission.asked && this.permission.allowed) {
          this.permissionResult(true);
        }
      }
    } else if (this.permission.name == "notification") {
      this.permissionNotification();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.text {
  padding: 48px;
  border-radius: 10px;
  box-sizing: border-box;
}

.text p,
.text h1 {
  text-align: left;
}

.text h1 {
  font-size: 24px;
  font-weight: 800;
  color: black;
}
</style>
