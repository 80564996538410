import Vue from 'vue'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMeta from 'vue-meta'
import VueRouter from 'vue-router'
import VueTouch from 'vue-touch'
import App from './App.vue'


Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(VueMeta)
Vue.use(VueRouter)
Vue.use(VueTouch, {name: 'v-touch'})

new Vue({
  vuetify,
  metaInfo: () => ({
    title: 'Afzetcontainers Spotten',
    meta: [
      {
        name: "viewport",
        content:
          "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no",
      },
      { name: "apple-mobile-web-app-capable", content: "yes" },
      { name: "mobile-web-app-capable", content: "yes" },
      { name: "theme-color", content: "#318d37" },
    ],
  }),
  render: h => h(App)
}).$mount('#app')

