<template>
  <div class="overlay">
    <div class="tip shadow">
      <div class="header">
        <h1>{{ this.title }}</h1>
        <span class="tip_pill"><v-icon color="white" left>mdi-lightbulb-on</v-icon>Tip</span>
      </div>
      <p>
        {{ this.text }}
      </p>
      <button class="button green" @click="readTip()"><v-icon color="white" left>mdi-check</v-icon>Duidelijk</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "tip",
  props: ["title", "text"],
  data: function () {
    return {};
  },
  methods: {
    readTip: function () {
      this.$emit("closeTip");
    },
  }
};
</script>
<style scoped>
.overlay{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, .3);
  z-index: 50;
}

.tip {
  margin: 24px;
  background-color: white;
  border-radius: 10px;
  padding: 24px 32px;
  text-align: left;

}

.tip_pill {
  background-color: var(--light-color);
  border-radius: 30px;
  padding: 8px 16px;
  color: white;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  margin-left: 16px;
}

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: flex-start;
  align-content: flex-start;
}

.header h1 {
  font-size: 24px;
  color: black;
  font-weight: 500;
}

.p {
  margin-bottom: 24px;
}

.button {
  width: 100%;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->

