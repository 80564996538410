<template>
  <div
    class="question_box shadow"
  >
    <h1>
      {{ this.question_text }}
    </h1>
    <div class="buttons_wrapper">
      <button class="button green shadow left" v-on:click="sendAnswer('prim')">
        <v-icon left color="#f7fcf8">{{ this.answer_prim_icon }}</v-icon>
        {{ this.answer_prim_text }}
      </button>
      <button class="button white shadow" v-on:click="sendAnswer('sec')">
        <v-icon left color="#246628">{{ this.answer_sec_icon }}</v-icon>
        {{ this.answer_sec_text }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "question_box",
  props: [
    "question_text",
    "answer_prim_text",
    "answer_prim_icon",
    "answer_sec_text",
    "answer_sec_icon",
  ],
  data: function () {
    return {
      enterClass: false,
      leaveClass: false,
    };
  },
  methods: {
    sendAnswer: function (val) {
      console.log("clicked answer");
      this.$emit("sendAnswer", val);
    },
  },
  created(){
    this.enterClass = true;
    this.leaveClass = false;
  },
  beforeDestroy() {
    this.enterClass = false;
    this.leaveClass = true;
  },
};
</script>
<style scoped>
.question_box {
  background-color: white;
  width: 100%;
  padding: 16px;
  border-radius: 10px;
}

h1 {
  font-size: 24px;
  color: black;
  text-align: left;
  font-weight: 400;
}

.buttons_wrapper {
  display: flex;
  width: 100%;
  margin-top: 40px;
}

.button {
  margin: 0;
  width: 100%;
}

.button.left {
  margin-right: 16px;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->

