<template>
  <div class="wrapper">
    <div class="navigation_wrapper glassmorphism">
        <button
          class="button white shadow"
          @click="backFunction()"
        >
          <v-icon color="#3A3A3A">mdi-arrow-left</v-icon>
        </button>
        <button class="button white invisible">
          <v-icon color="#3A3A3A">mdi-arrow-left</v-icon>
        </button>
        <button
          class="button white shadow"
          @click="openMenu()"
        >
          <v-icon color="#3A3A3A">mdi-menu</v-icon>
        </button>
        <!-- <v-select
          v-show="change_camera"
          :items="cameras"
          label="Selecteer de camera"
          item-text="label"
          item-value="deviceid"
          solo
          outlined
          v-model="selected_camera"
        ></v-select> -->
    </div>
    <ScoreboardUser
      v-for="(user, index) in this.sortedArray"
      :key="user.id"
      v-bind:user="user"
      v-bind:isUser="user.id == curUser.id"
      v-bind:number="index"
    >
    </ScoreboardUser>
  </div>
</template>

<script>
import ScoreboardUser from "./Scoreboard_User.vue";

export default {
  components: { ScoreboardUser },
  name: "Scoreboard",
  props: ["users", "curUser"],
  data: function () {
    return {};
  },
  methods: {
    orderScore: function (a, b) {
      if (a.score < b.score) {
        return -1;
      }
      if (a.score > b.score) {
        return 1;
      }
      return 0;
    },
    backFunction: function(){
      this.$emit('backToCamera');
    },
    openMenu: function(){
      this.$emit('openMenu')
    }
  },
  computed: {
    sortedArray: function () {
      var sorted = this.users;
      function compare(a, b) {
        if (a.score < b.score) return 1;
        if (a.score > b.score) return -1;
        return 0;
      }
      sorted = sorted.sort(compare)
      console.log(sorted)
      return sorted.sort(compare);
    },
  },
  mounted() {

  },
};
</script>
<style scoped>
.wrapper {
  width: 100%;
  height: 100%;
  padding: 24px;
  background-color: var(--lightest-color);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: calc(107px + 24px);
}

h1 {
  font-weight: 900;
  font-size: 64px;
  color: #038000;
  margin-bottom: 24px;
}

p {
  font-size: 24px;
  color: black;
}

.text_wrapper {
  background-color: white;
  border-radius: 10px;
  padding: 64px 24px;
  margin: auto 0;
}

.button_wrapper {
  width: 100%;
}

.navigation_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 24px;
}

.invisible {
  opacity: 0;
}
</style>