<template>
  <div class="user"
  v-bind:class="{ currentUser: isUser }">
  <div class="name_wrapper">
    <h2 class="position">{{ "#" + (this.number + 1) }}</h2>
    <h1 class="name">{{ this.fullname }}</h1>
  </div>
    <h2 class="score">{{ this.user.score }}</h2>
  </div>
</template>

<script>
export default {
  name: "ScoreboardUser",
  props: ["user", "isUser", "number"],
  data: function () {
    return {};
  },
  methods: {},
  computed: {
    fullname: function () {
      return this.user.name + " " + this.user.last_name;
    },
  },
};
</script>
<style scoped>
.user {
  width: 100%;
  display: flex;
  padding: 24px 16px;
  justify-content: space-between;
  /* margin: 8px 0; */
  /* background-color: white; */
  /* border-radius: 10px; */
}

.user h1 {
  font-size: 18px;
  color: #585858;
  font-weight: 500;
}

.user h2 {
  font-size: 18px;
  color: #585858;
  font-weight: 400;
}

.currentUser h1, .currentUser h2 {
  color: black;
  font-weight: 600;
}

.name {
  justify-self: flex-start;
}

.score {
  justify-self: flex-end;
}

.name_wrapper {
  display: flex;
  flex-direction: row;
}

.name_wrapper .position {
  margin-right: 16px;
}

</style>