<template>
  <div class="login_wrapper">
    <div class="input_wrapper shadow">
      <h1>login</h1>
      <v-text-field label="Emailadres" v-model="username_in"></v-text-field>
      <v-text-field
        :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showpass ? 'text' : 'password'"
        label="Wachtwoord"
        @click:append="showpass = !showpass"
        v-model="password_in"
      ></v-text-field>
      <div>
        <button class="button green shadow" @click="pageRedirect('client')">
          Client
        </button>
        <button class="button green shadow" @click="pageRedirect('admin')">
          Admin
        </button>
      </div>
      <p v-if="wrong">De ingevulde gegevens zijn incorrect.</p>
    </div>
  </div>
</template>

<script scoped>
export default {
  name: "login",
  props: ["user"],
  data: function () {
    return {
      username_in: "",
      password_in: "",
      wrong: false,
      showpass: false,
    };
  },
  methods: {
    login: function () {
      if (
        this.username_in == this.user.name &&
        this.password_in == this.user.password
      ) {
        this.user.loggedin = true;
        return true;
      } else {
        this.wrong = true;
        return false;
      }
    },
    pageRedirect: function (val) {
      this.$emit("pageRedirect", val);
    },
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login_wrapper {
  height: 100%;
  background-color: var(--lightest-color) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input_wrapper {
  background-color: white;
  max-width: 500px;
  border-radius: 10px;
  margin: 0 auto;
  padding: 40px;
  height: auto;
}

.button_wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding: 24px;
  justify-content: center;
}

.v-input__icon--append .button {
  margin: 0 !important;
}
</style>