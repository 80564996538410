<template>
  <div class="menu">
    <div class="navigation">
      <button @click="closeMenu()">
        <v-icon left color="black">mdi-close</v-icon>Menu sluiten
      </button>
    </div>
    <div class="main">
      <button class="button white shadow" @click="changeCamera()">
        <v-icon color="#3A3A3A">mdi-camera-flip</v-icon>
      </button>
      <button class="button white shadow" @click="resetScore()">
        <v-icon color="#3A3A3A">mdi-delete-forever</v-icon>
      </button>
      <v-select
        v-show="show_change_camera"
        :items="cameras"
        label="Selecteer de camera"
        item-text="label"
        item-value="deviceid"
        solo
        outlined
        v-model="selected_camera"
      ></v-select>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  props: [],
  data: function () {
    return {
      selected_camera: 0,
      show_change_camera: false,
      cameras: new Array(),
    };
  },
  watch: {
    selected_camera: function (val) {
      this.show_change_camera = false;
      this.$emit("changeCamera", val);
    },
  },
  methods: {
    closeMenu: function () {
      this.$emit("closeMenu");
    },
    resetScore: function () {
      this.$emit("resetScore");
    },
    changeCamera: function () {
      let aCameras = new Array();
      navigator.mediaDevices.enumerateDevices().then(function (devices) {
        for (let i = 0; devices[i]; i++) {
          if (devices[i].kind == "videoinput") {
            aCameras.push({
              deviceid: devices[i].deviceId,
              label: devices[i].label,
              everything: devices[i],
            });
          }
        }
      });

      this.cameras = aCameras;
      this.show_change_camera = !this.show_change_camera;
    },
  },
  computed: {},
};
</script>
<style scoped>
.menu {
  /* width: 100%; */
  height: calc((var(--vh, 1vh) * 100) - (24px * 2));
  margin: 16px;
  padding: 24px;
  background: white;
  box-sizing: border-box;
  position: relative;
  z-index: 100;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

button {
  margin: 8px;
}
</style>