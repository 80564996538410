import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    application: {
        bar: 0,
        bottom: 0,
        footer: 0,
        insetFooter: 0,
        left: 0,
        right: 0,
        top: 0
    }

});
