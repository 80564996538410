<template>
  <div class="homescreen_wrapper">
    <div class="full_wrapper" v-if="!show_onboarding">
      <div class="image">
        <img src="../assets/homescreen.png" />
        <p class="shadow">
          Als je deze website sneller wilt bereiken kun je hem toevoegen aan je
          startscherm.
        </p>
      </div>
      <div class="button_wrapper">
        <button @click="backToCamera()" class="button white full_width shadow">
          <v-icon left color="#246628">mdi-close</v-icon>Nee dankje
        </button>
        <button
          v-on:click="show_onboarding = true"
          class="button green full_width shadow"
        >
          <v-icon left color="white">mdi-check</v-icon>Ja graag
        </button>
      </div>
    </div>
    <div class="wrapper_steps" v-if="show_onboarding && !isIos">
      <div class="steps">
        <h1>Het is zo simpel als..</h1>
        <div class="step shadow">
          <h2>1</h2>
          <p>
            Klik bovenaan het scherm op het instelling icoon
            <span class="icon_container"
              ><v-icon color="black">mdi-dots-vertical</v-icon></span
            >
            in de navigatiebalk.
          </p>
        </div>
        <div class="step shadow">
          <h2>2</h2>
          <p>
            Klik daarna op
            <span class="icon_container"
              ><v-icon color="black">mdi-cellphone</v-icon></span
            >
            'Toevoegen aan startscherm'.
          </p>
        </div>
        <div class="step shadow">
          <h2>3</h2>
          <p>
            Nu kun je de website altijd makkelijker bereiken via je startscherm!
          </p>
        </div>
      </div>
      <div class="button_wrapper">
        <button @click="backToCamera()" class="button green full_width shadow">
          <v-icon left color="white">mdi-arrow-left</v-icon>Terug naar camera
        </button>
      </div>
    </div>
    <div class="wrapper_steps" v-if="show_onboarding && isIos">
      <div class="steps">
        <h1>Het is zo simpel als..</h1>
        <div class="step shadow">
          <h2>1</h2>
          <p>
            Klik op het delen icoon
            <span class="icon_container"
              ><v-icon color="black">mdi-export-variant</v-icon></span
            >
            onderaan het scherm.
          </p>
        </div>
        <div class="step shadow">
          <h2>2</h2>
          <p>
            Klik daarna op 'Zet op beginscherm'
            <span class="icon_container"
              ><v-icon color="black">mdi-plus-box-outline</v-icon></span
            >
          </p>
        </div>
        <div class="step shadow">
          <h2>3</h2>
          <p>
            Nu kun je de website altijd makkelijker bereiken via je startscherm!
          </p>
        </div>
      </div>
      <div class="button_wrapper">
        <button class="button green full_width shadow">
          <v-icon left color="white">mdi-arrow-left</v-icon>Terug naar camera
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "onboardingHomescreen",
  props: ["isIos"],
  data: function () {
    return {
      show_onboarding: false,
    };
  },
  watch: {},
  methods: {
    backToCamera: function () {
      console.log("test");
      this.$emit("backToCamera");
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.homescreen_wrapper {
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.full_wrapper {
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  background-color: var(--lightest-color);
  z-index: 100;
  position: absolute;
  justify-content: flex-end;
}

.wrapper_steps {
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  background-color: var(--lightest-color);
  z-index: 100;
  position: absolute;
}

.button_wrapper {
  z-index: 150;
}

.text {
  padding: 48px;
  border-radius: 10px;
  box-sizing: border-box;
  flex-grow: 1;
  background-color: white;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.image p {
  background-color: white;
  bottom: 150px;
  z-index: 150;
  padding: 16px 24px;
  border-radius: 10px;
  color: black;
  font-size: 18px;
  font-weight: 600;
}

button {
  margin-top: 16px;
}

img {
  width: 80%;
  object-fit: contain;
  position: absolute;
  top: 24px;
  z-index: 100;
}

.icon_container {
  padding: 4px;
  background-color: #dfdfdf;
  border-radius: 50px;
  height: 22px;
  width: 22px;
}

.steps {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.steps h1 {
  font-size: 24px;
  color: black;
  margin-bottom: 32px;
}

.step {
  background-color: white;
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 10px;
  margin-bottom: 24px;
}

.step h2 {
  color: var(--darkest-color);
  font-size: 32px;
  margin-right: 24px;
}

.step p {
  font-size: 18px;
  text-align: left;
  color: black;
  margin: 0;
}
</style>
