<template>
  <div class="wrapper">
    <div class="text_wrapper shadow">
      <h1>+{{ get_score() }} score</h1>
      <p>Bedankt voor het sturen van de foto!</p>
    </div>
    <div class="button_wrapper">
      <button class="button green shadow" v-on:click="sendAnswer('camera')">
        <v-icon left color="#f7fcf8">mdi-reload</v-icon>Nog een foto sturen
      </button>
      <button class="button white shadow" v-on:click="sendAnswer('score')">
        <v-icon left color="#246628">mdi-podium</v-icon>Bekijk het scorebord
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DoneScreen",
  props: ["score", "prediction"],
  data: function () {
    return {};
  },
  methods: {
    sendAnswer: function (val) {
      this.$emit("doneScreenAnswer", val);
    },
    get_score: function(){
      //  return Math.round(this.prediction.probability * 10);
      return 1;
     },
     get_name: function(){
      //  return this.prediction.tagName;
      return '';
     }
  },
  computed: {
     
  },
};
</script>
<style scoped>
.wrapper {
  width: 100%;
  height: 100%;
  padding: 24px;
  background-color: var(--lightest-color);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

h1 {
  font-weight: 900;
  font-size: 64px;
  color: #038000;
  margin-bottom: 24px;
}

p {
  font-size: 24px;
  color: black;
}

.text_wrapper {
  background-color: white;
  border-radius: 10px;
  padding: 64px 24px;
  margin: auto 0;
}

.button_wrapper {
  width: 100%;
}

.button {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  margin-top: 16px;
}
</style>